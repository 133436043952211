

@font-face {
    font-family: 'Countryside';
    src: url('./fonts/Countryside-YdKj.ttf') format('truetype'); /* Update the path and file name */
    /* You can add additional font formats here, like woff or woff2 */
  }

  @font-face {
    font-family: 'TrajanPro';
    src: url('./fonts/TrajanPro-Regular.otf') format('truetype'); /* Update the path and file name */
    /* You can add additional font formats here, like woff or woff2 */
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat-Regular.ttf') format('truetype'); /* Update the path and file name */
    /* You can add additional font formats here, like woff or woff2 */
  }
  @font-face {
    font-family: 'Montserrat-medium';
    src: url('./fonts/Montserrat-Medium.ttf') format('truetype'); /* Update the path and file name */
    /* You can add additional font formats here, like woff or woff2 */
  }

  @font-face {
    font-family: 'Montserrat-bold';
    src: url('./fonts/Montserrat-Bold.ttf') format('truetype'); /* Update the path and file name */
    /* You can add additional font formats here, like woff or woff2 */
  }

  @font-face {
    font-family: 'Montserrat-light';
    src: url('./fonts/Montserrat-Light.ttf') format('truetype'); /* Update the path and file name */
    /* You can add additional font formats here, like woff or woff2 */
  }